<template>
  <div>
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="单位名称">
        <a-input placeholder="请输入单位名称" v-decorator="['name']" />
      </a-form-item>
      <a-form-item label="单位类型">
        <a-select v-decorator="['type']">
          <a-select-option value="COMPANY"> 公司 </a-select-option>
          <a-select-option value="UNIVERSITY"> 学校 </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { addTeamGradeUnit } from "@/api/evaluation";
export default {
  name: "AddTermForm",
  components: {},
  props: {},
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((error, values) => {
          addTeamGradeUnit(values).then((res) => {
            if (res.code == 0) {
              this.$message.success("新增成功！");
              resolve(true);
            } else {
              this.$message.error("新增失败！");
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped>
</style>