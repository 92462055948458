import request from '@/utils/request'

const baseURL = '/evaluation/api'

const evaluationApi = {
	// 团队评分体系单位列表
	GetTeamGradeUnitList: '/evaluation-data/team/list-entity',
	AddTeamGradeUnit: '/evaluation-data/team/add-entity',
	DeleteTeamGradeUnit: '/evaluation-data/team/del-entity',
	// 团队评分体系单位简称
	GetTeamGradeUnitAbbr: '/evaluation-data/team/list-abbreviation',
	AddTeamGradeUnitAbbr: '/evaluation-data/team/add-abbreviation',
	DeleteTeamGradeUnitAbbr: '/evaluation-data/team/del-abbreviation',
	// 团队评分体系单位榜单分数
	GetTeamGradeUnitTopListScore: '/evaluation-data/team/list-rank',
	AddTeamGradeUnitTopListScore: '/evaluation-data/team/add-rank',
	EditTeamGradeUnitTopListScore: '/evaluation-data/team/edit-rank',
	DeleteTeamGradeUnitTopListScore: '/evaluation-data/team/del-rank'
}

export function getTeamGradeUnitList() {
	return request({
		url: baseURL + evaluationApi.GetTeamGradeUnitList,
		method: 'get',
	})
}

export function addTeamGradeUnit(parameter) {
	return request({
		url: baseURL + evaluationApi.AddTeamGradeUnit,
		method: 'post',
		data: parameter
	})
}

export function deleteTeamGradeUnit(parameter) {
	return request({
		url: baseURL + evaluationApi.DeleteTeamGradeUnit,
		method: 'post',
		params: parameter
	})
}

export function getTeamGradeUnitAbbr(parameter) {
	return request({
		url: baseURL + evaluationApi.GetTeamGradeUnitAbbr,
		method: 'get',
		params: parameter
	})
}

export function addTeamGradeUnitAbbr(parameter) {
	return request({
		url: baseURL + evaluationApi.AddTeamGradeUnitAbbr,
		method: 'post',
		data: parameter
	})
}

export function deleteTeamGradeUnitAbbr(parameter) {
	return request({
		url: baseURL + evaluationApi.DeleteTeamGradeUnitAbbr,
		method: 'post',
		params: parameter
	})
}

export function getTeamGradeUnitTopListScore(parameter) {
	return request({
		url: baseURL + evaluationApi.GetTeamGradeUnitTopListScore,
		method: 'get',
		params: parameter
	})
}

export function addTeamGradeUnitTopListScore(parameter) {
	return request({
		url: baseURL + evaluationApi.AddTeamGradeUnitTopListScore,
		method: 'post',
		data: parameter
	})
}

export function editTeamGradeUnitTopListScore(parameter) {
	return request({
		url: baseURL + evaluationApi.EditTeamGradeUnitTopListScore,
		method: 'post',
		data: parameter
	})
}

export function deleteTeamGradeUnitTopListScore(parameter) {
	return request({
		url: baseURL + evaluationApi.DeleteTeamGradeUnitTopListScore,
		method: 'post',
		params: parameter
	})
}