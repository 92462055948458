<template>
  <div class="unit-list">
    <div class="action-area">
      <a-button
        v-if="hasAction('add-team-evaluation-unit')"
        type="primary"
        icon="plus"
        @click="handleAdd"
        >新增单位</a-button
      >
      <div v-else></div>
      <a-input-search
        v-model="params.inputStr"
        class="input-bar"
        placeholder="请输入关键词搜索"
        @search="fetchData"
      />
    </div>
    <a-table
      :columns="table.columns"
      :data-source="params.resultList"
      :loading="table.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    >
      <template slot="action" slot-scope="text, record">
        <a
          @click="handleEdit(record)"
          v-if="hasRouter('team-evaluation-unit-manage')"
          >编辑单位详情</a
        >
        <a-divider
          type="vertical"
          v-if="
            hasRouter('team-evaluation-unit-manage') &&
            hasAction('delete-team-evaluation-unit')
          "
        />
        <a-popconfirm
          title="是否确认删除该单位？"
          ok-text="是"
          cancel-text="否"
          @confirm="handleDelete(record)"
          v-if="hasAction('delete-team-evaluation-unit')"
        >
          <a-icon
            slot="icon"
            type="close-circle"
            theme="filled"
            style="color: red"
          />
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
import AddTermForm from "./components/AddTermForm.vue";
import { getTeamGradeUnitList, deleteTeamGradeUnit } from "@/api/evaluation";
export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      params: {
        inputStr: "",
        unitList: [],
        resultList: [],
      },
      table: {
        loading: false,
        columns: [
          {
            title: "单位名称",
            dataIndex: "name",
          },
          {
            title: "单位类型",
            dataIndex: "type",
            align: "center",
            customRender: (text) => {
              switch (text) {
                case "COMPANY":
                  return "公司";
                case "UNIVERSITY":
                  return "学校";
              }
            },
          },
        ],
      },
    };
  },
  created() {
    if (
      (this.hasRouter("team-evaluation-unit-manage") ||
        this.hasAction("delete-team-evaluation-unit")) &&
      this.table.columns.length < 3
    ) {
      this.table.columns.push({
        title: "管理",
        scopedSlots: {
          customRender: "action",
        },
        align: "center",
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      this.table.loading = true;
      getTeamGradeUnitList()
        .then((res) => {
          this.params.unitList = res.data;
          this.params.resultList = this.params.unitList;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    handleAdd() {
      this.$dialog(
        AddTermForm,
        {
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "新增评价单位",
          width: 600,
          centered: true,
          maskClosable: false,
          okText: "保存",
        }
      );
    },
    handleEdit(record) {
      this.$router.push({
        path: "/evaluation-system/team-grade/unit-manage",
        query: {
          id: record.id,
          name: record.name,
        },
      });
    },
    handleDelete(record) {
      deleteTeamGradeUnit({ entityId: record.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.loadData();
        } else {
          this.$message.error("删除失败！");
        }
      });
    },
    fetchData(keyword) {
      if (keyword !== "") {
        this.params.resultList = this.params.unitList.filter((item) =>
          item.name.includes(keyword)
        );
      } else {
        this.params.resultList = this.params.unitList;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.unit-list {
  background-color: white;
  padding: 20px;
  .action-area {
    display: flex;
    justify-content: space-between;
    .input-bar {
      width: 350px;
      margin-bottom: 10px;
    }
  }
}
</style>